export function useHelpers() {
  function capitalize(value: string) {
    return value.length
      ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
      : ''
  }

  function removeNullProperties(object: any): any {
    return Object.fromEntries(
      Object.entries(object).filter(([_, value]) => value !== null),
    )
  }

  function stripEmptyKeys(object: { [key: string]: any }) {
    if (!object || !Object.keys(object).length) return {}
    return Object.fromEntries(
      Object.entries(object).filter(
        ([key, value]) => key && value != null && value !== '',
      ),
    )
  }

  return {
    capitalize,
    removeNullProperties,
    stripEmptyKeys,
  }
}
