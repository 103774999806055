<script setup lang="ts">
  import { i18NextPromise } from './plugins/i18n'

  useHead({
    title: 'Loftware',
    meta: [{ name: 'description', content: 'Loftware Cloud Designer' }],
    link: [
      {
        rel: 'manifest',
        href: '/site.webmanifest',
      },
      {
        rel: 'apple-touch-icon',
        type: 'image/png',
        href: '/apple-touch-icon.png',
      },
      {
        rel: 'icon',
        type: 'image/png',
        href: '/favicon-32x32.png',
      },
      {
        rel: 'icon',
        href: '/favicon.ico',
        sizes: 'any',
      },
    ],
  })

  const user = useUserStore()
  const api = useHttpClient()
  const { fetchCountries } = useWorkspace()

  async function init() {
    try {
      const userData = await api.users.getMe()
      user.setMyData(userData)
      await user.fetchPartner()
      await fetchCountries()
      await i18NextPromise
    } catch (error) {
      console.log(error)
    }
  }

  onMounted(() => {
    if (!user.isLoggedIn) return
    init()
  })
</script>

<template>
  <RouterView />
</template>
