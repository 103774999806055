<script setup lang="ts">
  import { router } from '~/router'

  onBeforeMount(() => {
    router.push({ name: 'subscription-portal-sign-in' })
  })
</script>
<template>
  <UiWrapper>
    <div class="flex flex-col justify-start gap-2 text-left">
      <h1 class="text-medium mb-6 text-lg text-gray-500">Login to</h1>
      <!--      <router-link
        :to="{ name: 'checkout-sign-in' }"
        class="text-blue-300 font-medium"
        >Customer Checkout</router-link
      >-->
      <router-link
        :to="{ name: 'subscription-portal-sign-in' }"
        class="font-medium text-blue-300"
      >
        Subscription Management Portal
      </router-link>
    </div>
  </UiWrapper>
</template>

<route lang="yaml">
meta:
layout: home
</route>
