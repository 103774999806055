import { ApiKy } from './index'
import { UniquePrefixResponse } from '~/types/url-prefix'

export default class Api extends ApiKy {
  validate(urlPrefix: string) {
    return this.ky
      .get(`${this.url}/validate`, { searchParams: { urlPrefix } })
      ?.json<UniquePrefixResponse>()
  }
}
