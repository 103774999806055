import { middlewareInstructions } from './types'

export default function isLoggedIn(): middlewareInstructions {
  const user = useUserStore()

  if (user.isLoggedIn) {
    // TO-DO: for now, we jsut log the user
    user.logout(null)
  }
  return { to: { name: '' } }
}
