import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
import { Role, User } from '~/types/users'
import { router } from '~/router'
import { RouteLocationRaw } from 'vue-router'
import { Partner } from '~/types/partners'
import { userIs } from '~/middleware/helpers'
import { JwtPayload, jwtDecode } from 'jwt-decode'

interface AccessTokenPayload extends JwtPayload {
  partner_roles?: { partnerId: string; role: Role }[]
  user_id?: string
  groups?: any
  admin_id?: string
}

export const useUserStore = defineStore('user', () => {
  const refreshKey = ref(0)
  const api = useHttpClient()
  const doNotLogout = ref(false)
  /* User data */
  const defaultRoles: string[] = []
  const me = useStorage('user-me', {
    id: '',
    email: '',
    roles: defaultRoles,
    firstName: '',
    lastName: '',
  })
  function resetStore() {
    me.value = {
      id: '',
      email: '',
      roles: defaultRoles,
      firstName: '',
      lastName: '',
    }
    adminId.value = ''
    partner.value = { id: '', name: '' }
    // .replace error because of this line
    tokens.value = { access: '', refresh: '' }
    refreshKey.value++
    selectedPartnerId.value = ''
  }
  const selectedPartnerId = useStorage('selectedPartnerId', '')

  async function changePartner(id: string) {
    selectedPartnerId.value = id
    await fetchPartner()
  }

  function changeDoNotLogout(value: boolean) {
    doNotLogout.value = value
  }

  const partner = ref({} as Partner)
  // @ts-ignore
  const partnerId = computed(() => {
    if (selectedPartnerId.value.length > 0) return selectedPartnerId.value
    if (userIs(Role.SUPER_ADMIN)) return ''
    const decoded = tokens.value.access.length
      ? jwtDecode<AccessTokenPayload>(tokens.value.access)
      : undefined
    const roles = decoded?.partner_roles ?? []
    if (
      roles?.length &&
      roles.find((role) => role.partnerId === partnerId.value)
    ) {
      return (
        roles.find((role) => role.partnerId === partnerId.value)?.partnerId ??
        ''
      )
    }
    if (!roles?.length && !decoded?.groups.includes(Role.SUPER_ADMIN)) {
      logout({
        name: 'subscription-portal-sign-in',
      })
    }

    return roles?.length && roles[roles.length - 1]?.partnerId
      ? roles[roles.length - 1].partnerId
      : ''
  })

  const allPartnerIDs = computed(() => {
    const decoded = tokens.value.access.length
      ? jwtDecode<AccessTokenPayload>(tokens.value.access)
      : undefined
    const roles = decoded?.partner_roles ?? []
    return roles?.length ? roles.map((obj) => obj.partnerId) : ''
  })

  async function fetchPartner() {
    if (!partnerId.value) return
    const partnerResponse = await api.partners.getSingle(partnerId.value)
    partner.value = partnerResponse
    return partnerResponse
  }

  function getUserId() {
    const decoded = tokens.value.access.length
      ? jwtDecode<AccessTokenPayload>(tokens.value.access)
      : undefined
    return decoded?.user_id ?? undefined
  }
  function setMyData(user: User) {
    me.value.id = user.id
    me.value.email = user.email
    me.value.firstName = user.firstName ?? ''
    me.value.lastName = user.lastName ?? ''
    me.value.roles = user.roles?.length ? user.roles : defaultRoles
  }
  function setMyAttr(key: 'email' | 'firstName' | 'lastName', value: string) {
    me.value[key] = value
  }

  /* Logged-in */
  const isLoggedIn = computed(() => {
    return !!(getTokens.value.access && getTokens.value.refresh)
  })
  function logout(to: RouteLocationRaw | null) {
    try {
      resetStore()
    } catch (error) {
      localStorage.clear()
    } finally {
      if (to) router.push(to)
    }
  }

  /* Tokens */
  const tokens = useStorage('tokens', { access: '', refresh: '' })
  const getTokens = computed(() => {
    return { access: tokens.value.access, refresh: tokens.value.refresh }
  })
  function setTokens(accessToken: string, refreshToken: string) {
    if (!accessToken || !refreshToken) return
    tokens.value.access = accessToken
    tokens.value.refresh = refreshToken
  }

  const adminId = useStorage('admin-id', '')
  function checkForAdminId(accessToken: string) {
    const decoded = jwtDecode<AccessTokenPayload>(accessToken)
    if (decoded?.admin_id) {
      adminId.value = decoded.admin_id
    }
  }

  async function stopImpersonating() {
    const { setup } = useWorkspace()
    const decoded = jwtDecode<AccessTokenPayload>(tokens.value.access)
    if (decoded?.admin_id) {
      const { accessToken, refreshToken } = await api.auth.stopImpersonating()
      await setup(accessToken, refreshToken)
    }
    selectedPartnerId.value = ''
    adminId.value = ''
  }

  return {
    adminId,
    isLoggedIn,
    getTokens,
    setTokens,
    checkForAdminId,
    stopImpersonating,
    me,
    partner,
    partnerId,
    allPartnerIDs,
    fetchPartner,
    changeDoNotLogout,
    doNotLogout,
    setMyData,
    setMyAttr,
    getUserId,
    resetStore,
    logout,
    changePartner,
  }
})
