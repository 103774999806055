import { Role } from '~/types/users'

export function userIs(role: Role) {
  const user = useUserStore()
  // TO-DO we only check first role for now.
  // Same at brand-sidebar.vue --> we didn't decide yet how to handle user
  // that is partner and admin at the same time
  return user.me.roles[0] === role
}

export function userHasRole(...roles: Role[]) {
  const user = useUserStore()
  const userRole = user.me.roles[0]

  return roles.includes(userRole as unknown as Role)
}

export function userHasNoRoles() {
  const user = useUserStore()
  return !user.me.roles.length
}
