import { useStorage } from '@vueuse/core'
import { Country, CountryCode, CurrencySymbols } from '~/types/countries'

export function useWorkspace() {
  const api = useHttpClient()
  const user = useUserStore()
  const countryCodeList = Object.keys(CountryCode)
  const initialCountries: Country[] = []
  const countries = ref(useStorage('countries', initialCountries))

  /* Setup */
  async function setup(accessToken: string, refreshToken: string) {
    user.setTokens(accessToken, refreshToken)
    user.checkForAdminId(accessToken)

    const userData = await api.users.getMe()
    user.setMyData(userData)
    fetchCountries()
  }

  /* Countries */
  async function fetchCountries() {
    try {
      const data = await api.countries.get()
      countries.value = data
    } catch (error) {
      console.log(error)
    }
  }

  function getCountryInfo(targetCode: string) {
    if (!targetCode) return null
    const country = countries?.value?.find(
      (c: Country) => c.code === targetCode,
    )

    if (!country) return null
    const { region, phoneCode, currencyCode, code, serverLocation } = country
    return {
      region,
      code,
      phoneCode,
      currencyCode,
      currency: CurrencySymbols[currencyCode],
      serverLocation,
    }
  }

  return { countryCodeList, getCountryInfo, fetchCountries, setup, countries }
}
