import { Role } from '~/types/users'
import { userIs, userHasNoRoles } from './helpers'
import { middlewareInstructions } from './types'

export default function isCustomer(): middlewareInstructions {
  if (userHasNoRoles()) {
    return { to: { name: 'subscription-portal-sign-in' }, actions: 'logout' }
  }
  if (userIs(Role.CUSTOMER)) return { to: { name: '' } }
  return userIs(Role.PARTNER)
    ? { to: { name: 'subscription-portal-subscriptions' } }
    : { to: { name: 'subscription-portal-partners' } }
}
