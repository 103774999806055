import { ApiKy } from './index'
import { RetrySalesforceResponse } from '~/types/admin'

export default class Api extends ApiKy {
  retrySalesforceRequest(requestId: string) {
    return this.ky
      .post(`${this.url}/salesforce/retry-request/${requestId}`)
      ?.json<RetrySalesforceResponse>()
  }
}
