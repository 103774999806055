import { getFallbackName } from '~/router'
import { middlewareContext, middlewareInstructions } from './types'

export default function isLoggedIn(
  ctx: middlewareContext,
): middlewareInstructions {
  const user = useUserStore()
  const fallbackName = getFallbackName(ctx.to)
  if (!user.isLoggedIn) return { to: { name: fallbackName.loggedOut } }
  return { to: { name: '' } }
}
