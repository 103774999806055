import { ApiKy } from './index'
import {
  CheckoutData,
  CheckoutAccount,
  CheckoutCompletePayload,
} from '~/types/checkout'

export default class Api extends ApiKy {
  create(parentId: string | undefined = undefined) {
    return this.ky
      .post(this.url, { json: { parentId } })
      ?.json<{ checkoutId: string }>()
  }

  get(id: string) {
    return this.ky.get(`${this.url}/${id}`)?.json<CheckoutData>()
  }

  updateDetails(id: string, payload: CheckoutData) {
    return this.ky
      .patch(`${this.url}/${id}/details`, { json: payload })
      ?.json<CheckoutData>()
  }

  updateAccount(id: string, payload: CheckoutAccount) {
    return this.ky
      .patch(`${this.url}/${id}/account`, { json: payload })
      ?.json<CheckoutAccount>()
  }

  /**
   * This api call does two things:
   * 1. creates user and subscription
   * 2. deletes checkout
   */
  complete(id: string, payload: CheckoutCompletePayload) {
    return this.ky
      .post(`${this.url}/${id}/complete`, {
        json: payload,
      })
      ?.json()
  }
}
