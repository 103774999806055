import { ApiKy } from './index'
import {
  Reseller,
  ResellersResponse,
  ResellersSearchParams,
} from '~/types/resellers'

const { stripEmptyKeys } = useHelpers()

export default class Api extends ApiKy {
  get(searchParams: ResellersSearchParams) {
    const params = stripEmptyKeys(searchParams)
    return this.ky
      .get(this.url, { searchParams: params })
      .json<ResellersResponse>()
  }

  getSingle(resellerId: string) {
    return this.ky.get(`${this.url}/${resellerId}`)?.json<Reseller>()
  }
}
