import { Role } from '~/types/users'
import { userHasRole } from './helpers'
import { middlewareInstructions } from './types'
import { PartnerStatus } from '~/types/partners'

export default function isPendingPartner(): middlewareInstructions {
  const {
    partner: { status },
  } = useUserStore()

  if (
    userHasRole(Role.PARTNER, Role.DISTRIBUTOR) &&
    status !== PartnerStatus.ACTIVE
  )
    return { to: { name: 'subscription-portal-subscriptions' } }

  return { to: { name: '' } }
}
