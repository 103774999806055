import {
  GetPartnerUsersResponse,
  InvitedUser,
  InvitePartnerResponse,
  InviteUserResponse,
  Partner,
  PartnersResponse,
  PartnersSearchParams,
  PartnerUser,
  PaymentSource,
  UserInvitePayload,
} from '~/types/partners'
import { PaymentMethod } from '~/types/subscriptions'
import { ApiKy } from './index'
import { InvoicesResponse, PartnerInvoicesSearchParams } from '~/types/invoices'
import {
  CreditNotesResponse,
  PartnerCreditNotesSearchParams,
} from '~/types/credit-notes'

const { stripEmptyKeys } = useHelpers()

export default class Api extends ApiKy {
  get(searchParams: PartnersSearchParams) {
    const params = stripEmptyKeys(searchParams)
    return this.ky
      .get(this.url, { searchParams: params })
      ?.json<PartnersResponse>()
  }

  delete(partnerId: string) {
    return this.ky.delete(`${this.url}/${partnerId}`)
  }

  getSingle(partnerId: string) {
    return this.ky.get(`${this.url}/${partnerId}`)?.json<Partner>()
  }

  getDealerDemoAccounts(partnerId: string) {
    return this.ky.get(`${this.url}/${partnerId}/demo-accounts`)?.json<
      {
        inviteEmail: string
        accessLink: string
        productType: string
      }[]
    >()
  }

  getUsers(partnerId: string, searchParams: PartnerInvoicesSearchParams) {
    const params = stripEmptyKeys(searchParams)
    return this.ky
      .get(`${this.url}/${partnerId}/users`, { searchParams: params })
      ?.json<GetPartnerUsersResponse>()
  }

  getPaymentSource(partnerId: string) {
    return this.ky
      .get(`${this.url}/${partnerId}/payment-sources`)
      ?.json<PaymentSource[]>()
  }

  updatePaymentSource(
    partnerId: string,
    payload: {
      paymentMethod: PaymentMethod
      braintreePaymentToken: string
    },
  ) {
    return this.ky
      .put(`${this.url}/${partnerId}/payment-sources`, { json: payload })
      ?.json<PaymentSource>()
  }

  updateSingle(partnerId: string, payload: Partial<Partner>) {
    return this.ky
      .patch(`${this.url}/${partnerId}`, { json: payload })
      ?.json<Partner>()
  }

  getShortlist(countryCode: string) {
    const query = countryCode ? `?country=${countryCode}` : ''
    return this.ky.get(`${this.url}/shortlist${query}`)?.json<Partner[]>()
  }

  // TO-DO: add types for responses
  invitePartner(payload: Partial<Partner>) {
    return this.ky
      .post(`${this.url}/invite`, { json: payload })
      ?.json<InvitePartnerResponse>()
  }

  inviteUser(partnerId: string, payload: UserInvitePayload) {
    return this.ky
      .post(`${this.url}/${partnerId}/users/invite`, { json: payload })
      ?.json<InviteUserResponse>()
  }

  getInvoices(partnerId: string, searchParams: PartnerInvoicesSearchParams) {
    const params = stripEmptyKeys(searchParams)
    return this.ky
      .get(`${this.url}/${partnerId}/invoices`, { searchParams: params })
      ?.json<InvoicesResponse>()
  }

  getCreditNotes(
    partnerId: string,
    searchParams: PartnerCreditNotesSearchParams,
  ) {
    const params = stripEmptyKeys(searchParams)
    return this.ky
      .get(`${this.url}/${partnerId}/credit-notes`, { searchParams: params })
      ?.json<CreditNotesResponse>()
  }

  getInvoiceUrl(partnerId: string, invoiceId: string) {
    return this.ky
      .get(`${this.url}/${partnerId}/invoices/${invoiceId}/pdf`)
      ?.json<{ downloadUrl: string }>()
  }

  claimUser(partnerUserId: string) {
    return this.ky.post(`${this.url}/users/${partnerUserId}/claim`)
  }

  claimPartner(partnerId: string, payload: Partial<Partner>) {
    return this.ky.post(`${this.url}/${partnerId}/claim`, { json: payload })
  }

  deleteUser(partnerId: string, userId: string) {
    return this.ky.delete(`${this.url}/${partnerId}/users/${userId}`)
  }

  editUser(
    partnerId: string,
    userId: string,
    payload: { firstName: string; lastName: string },
  ) {
    return this.ky
      .patch(`${this.url}/${partnerId}/users/${userId}`, { json: payload })
      ?.json<PartnerUser>()
  }

  resendTeamMemberInvite(partnerId: string, userId: string) {
    return this.ky
      .post(`${this.url}/${partnerId}/users/${userId}/resend-invite`)
      ?.json<InvitedUser>()
  }

  resendInvite(partnerId: string) {
    return this.ky
      .post(`${this.url}/${partnerId}/resend-invite`)
      ?.json<InvitePartnerResponse>()
  }
}
