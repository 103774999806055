import { AuthResponse, StopImpersonatingResponse } from '~/types/auth'
import { ApiKy } from './index'

export default class Api extends ApiKy {
  /**
   * @param {string} token JWT token obtained from the backend
   */
  login(token: string) {
    return this.ky
      .post(`${this.url}/login`, { json: { token } })
      ?.json<AuthResponse>()
  }

  getNewTokens(refreshToken: string, userId: string) {
    return this.ky
      .post(`${this.url}/token/refresh`, {
        json: {
          refreshToken,
          userId,
        },
      })
      ?.json<AuthResponse>()
  }

  impersonate(userId: string) {
    return this.ky
      .post(`${this.url}/impersonate`, { json: { userId: userId } })
      ?.json<AuthResponse>()
  }

  stopImpersonating() {
    return this.ky
      .delete(`${this.url}/impersonate`)
      ?.json<StopImpersonatingResponse>()
  }
}
