import i18next from 'i18next'
import Backend from 'i18next-locize-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

// Add new namespace here
const namespaces = [
  'common',
  'checkout',
  'countries',
  'errors',
  'portal',
  'usstates',
  'castates',
]

const locizeOptions = {
  projectId: import.meta.env.VITE_LOCIZE_PROJECT_ID,
  apiKey: import.meta.env.VITE_LOCIZE_API_KEY,
  version: 'latest',
  allowedAddOrUpdateHosts: ['localhost'],
  legacy: false,
}

export const languages: { [key: string]: { code: string; name: string } } = {
  en: { code: 'en', name: 'English' },
  de: { code: 'de', name: 'Deutsch' },
  fr: { code: 'fr', name: 'Français' },
  es: { code: 'es', name: 'Español' },
  it: { code: 'it', name: 'Italiano' },
}

// app.use is called in main.ts, waiting got i18nextPromise to be fulfilled
export const i18NextPromise = i18next
  .use(LanguageDetector)
  .use(Backend)
  .init({
    backend: locizeOptions,
    ns: namespaces,
    defaultNS: 'common',
    debug: false,
    fallbackLng: Object.keys(languages),
    saveMissing: import.meta.env.VITE_LOCIZE_SAVE_MISSING === 'true',
  })
