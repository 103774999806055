import { router } from '~/router'

export function logout(name: string) {
  clearData()
  router.push({ name })
}

export function clearData() {
  try {
    const checkout = useCheckoutStore()
    const user = useUserStore()
    user.resetStore()
    checkout.$reset()
    checkout.resetStore()
    checkout.activeStepIndex = 0
  } catch (error) {
    localStorage.clear()
  }
}

export function formatCurrency(cents: number | undefined) {
  const checkout = useCheckoutStore()
  if (!cents || !checkout.countryInfo.code) return
  return new Intl.NumberFormat(`en-${checkout.countryInfo.code}`, {
    style: 'currency',
    currency: checkout.countryInfo.currencyCode,
  }).format(cents)
}
