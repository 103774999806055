import { PublicClientApplication } from '@azure/msal-browser'

const loftwareAuthority = import.meta.env.VITE_LOFTWARE_AUTHORITY
const loftwareClientId = import.meta.env.VITE_LOFTWARE_CLIENT_ID

export const msalConfig = {
  auth: {
    clientId: loftwareClientId,
    authority: loftwareAuthority,
    knownAuthorities: [loftwareAuthority],
    redirectUri: '/subscription-portal/sign-in',
    postLogoutRedirectUri: '/subscription-portal/sign-in',
    // navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
}
export const loginRequest = {
  scopes: [`openid ${loftwareClientId}`],
}

export const msalInstance = new PublicClientApplication(msalConfig)
