import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
} from 'vue-router'
import { setupLayouts } from 'virtual:generated-layouts'
import generatedRoutes from 'virtual:generated-pages'
import { clearData } from '~/components/checkout/utils'
import Middleware from './middleware'

const routes = setupLayouts(generatedRoutes)
const router = createRouter({
  history: createWebHistory(),
  routes,
})

const fallback = {
  loggedIn: {
    checkout: 'checkout-id-details',
    portal: 'subscription-portal-partners',
  },
  loggedOut: {
    checkout: 'checkout-sign-in',
    portal: 'subscription-portal-sign-in',
  },
}

router.onError((error) => {
  if (
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed')
  ) {
    window.location.reload()
  }
})

function getFallbackName(route: RouteLocationNormalized) {
  const segment = route?.name?.toString().split('-')[0]
  const fallbackName = {
    loggedIn: 'index',
    loggedOut: 'index',
  }
  switch (segment) {
    case 'checkout':
      fallbackName.loggedIn = fallback.loggedIn.checkout
      fallbackName.loggedOut = fallback.loggedOut.checkout
      break
    case 'subscription':
      fallbackName.loggedIn = fallback.loggedIn.portal
      fallbackName.loggedOut = fallback.loggedOut.portal
      break
    default:
      fallbackName.loggedIn = 'index'
      fallbackName.loggedOut = 'index'
  }
  return fallbackName
}

function getQueryFromUrl(name: string) {
  return router.currentRoute.value.query[name]?.toString() ?? ''
}

/**
 * Navigation Guards
 */
let middleware: any = null
router.beforeEach((to, from) => {
  if (!middleware) middleware = new Middleware()
  if (!('middleware' in to.meta)) return // If page has no middleware, navigate to the page early
  const routeMiddleware = to.meta.middleware ?? []

  const instructions = middleware.checkMiddleware(routeMiddleware, { to, from })
  if (instructions.actions === 'logout') clearData()
  if (instructions?.to?.name) return { name: instructions?.to?.name }
})

export { router, routes, getFallbackName, getQueryFromUrl, fallback }
