import { UserModule } from '~/types/app'
import Toast, { PluginOptions, POSITION } from 'vue-toastification'
import '~/styles/toast.scss'

const options: PluginOptions = {
  position: POSITION.BOTTOM_CENTER,
  timeout: 4000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.8,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: true,
  rtl: false,
  transition: 'Vue-Toastification__fade',
}

export const install: UserModule = ({ app }) => {
  app.use(Toast, options)
}
