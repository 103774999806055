import { Step } from '~/types/ui'
import { RouteLocationNormalizedLoaded } from 'vue-router'

export function useStepper(data: Step[], i = 0) {
  const max = data.length - 1
  const steps = ref(data)
  const activeStepIndex = ref(i)
  const activeStep = computed(() => {
    const index = activeStepIndex.value
    const active = steps.value[index] ?? {}
    return {
      ...active,
      index: activeStepIndex.value,
    }
  })

  function setStepBasedOnRoute(route: RouteLocationNormalizedLoaded) {
    const index = steps.value.findIndex((step) => step.routeName === route.name)
    activeStepIndex.value = index
    return activeStep.value
  }

  function setStepBasedOnTitle(title: string) {
    const index = steps.value.findIndex((step) => step.title === title)
    activeStepIndex.value = index
    return activeStep.value
  }

  function goToStepNumber(to: number) {
    const index = to <= 0 ? 0 : to >= max ? max : to
    activeStepIndex.value = index
    return activeStep.value
  }

  return {
    activeStep,
    steps,
    setStepBasedOnRoute,
    setStepBasedOnTitle,
    goToStepNumber,
  }
}
