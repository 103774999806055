import { ClientTokenResponse } from '~/types/braintree'
import { ApiKy } from './index'

export default class Api extends ApiKy {
  /**
   * Create Braintree client token
   * https://developer.paypal.com/braintree/docs/guides/authorization/client-token
   */
  createClientToken() {
    return this.ky.post(`${this.url}/client-token`)?.json<ClientTokenResponse>()
  }
}
