import {
  ItemPrice,
  SubscriptionsEstimateResponse,
  SubscriptionEstimatePayload,
  PlansResponse,
  ChangeSubscriptionEstimateResponse,
} from '~/types/prices'
import { ApiKy } from './index'
import { CurrencyCode } from '~/types/countries'
import { BillingPeriod } from '~/types/subscriptions'

export default class Api extends ApiKy {
  get(currencyCode: CurrencyCode) {
    return this.ky
      .get(`${this.url}/items`, {
        searchParams: { currencyCode },
      })
      ?.json<ItemPrice[]>()
  }

  getItem(id: string) {
    return this.ky.get(`${this.url}/items/${id}`)?.json<ItemPrice>()
  }

  getPlans(currencyCode: CurrencyCode, billingPeriod: BillingPeriod) {
    return this.ky
      .get(`${this.url}/plans`, {
        searchParams: { currencyCode, billingPeriod },
      })
      ?.json<PlansResponse>()
  }

  postSubscriptionEstimate(
    partnerId: string,
    payload: SubscriptionEstimatePayload,
  ) {
    return this.ky
      .post(`${this.url}/subscriptions/partners/${partnerId}`, {
        json: payload,
      })
      ?.json<SubscriptionsEstimateResponse>()
  }

  getChangeSubscriptionEstimate(subscriptionId: string, numOfPrinters: number) {
    return this.ky
      .get(`${this.url}/subscriptions/${subscriptionId}`, {
        searchParams: { subscriptionId, numOfPrinters },
      })
      ?.json<ChangeSubscriptionEstimateResponse>()
  }
}
