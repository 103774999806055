import type { UserModule } from './types/app'
import App from './App.vue'
import { createApp } from 'vue'
import i18next from 'i18next'
import I18NextVue from 'i18next-vue'
import { i18NextPromise } from './plugins/i18n'
import { router, routes } from './router'
import './styles/index.scss'
import * as Sentry from '@sentry/vue'
import { version } from '../package.json'
import { msalPlugin } from './plugins/msalPlugin'
import { msalInstance } from './config/auth'

async function setup() {
  const app = createApp(App)

  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_URL,
    ignoreErrors: ['Request failed with status code 401'],
    integrations: [Sentry.browserTracingIntegration({ router })],
    release: version,
    tracesSampleRate: 0.5,
    enabled: import.meta.env.VITE_SENTRY_ENABLED === 'true',
  })

  app.use(router)
  app.use(msalPlugin, msalInstance)

  // Auto-import from /src/plugins
  Object.values(
    import.meta.glob<{ install: UserModule }>('./plugins/*.ts', {
      eager: true,
    }),
  ).forEach((i) => i.install?.({ app, router, routes }))

  try {
    await i18NextPromise
    app.use(I18NextVue, { i18next })
    app.mount('#app')
  } catch (error) {
    console.log(error)
  }
}

setup()
