import { ApiKy } from './index'

export default class Api extends ApiKy {
  get(id: string) {
    return this.ky.get(`${this.url}/${id}/pdf`)?.json<{ downloadUrl: string }>()
  }
  downloadPdfs(creditNoteIds: string[]) {
    return this.ky.post(`${this.url}/download-pdfs`, {
      json: { creditNoteIds },
    })
  }
}
