import { User } from '~/types/users'
import { ApiKy } from './index'

export default class Api extends ApiKy {
  getMe() {
    return this.ky.get(`${this.url}/me`)?.json<User>()
  }

  updateMe(payload: Partial<User>) {
    return this.ky.patch(`${this.url}/me`, { json: payload })?.json<User>()
  }
}
