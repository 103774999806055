/* eslint-disable no-param-reassign */
import ky from 'ky'
import { useUserStore } from '~/store/user'
import { Api } from '~/api'
import { AuthResponse } from '~/types/auth'
import { logout } from '~/components/checkout/utils'

import ApiAuth from '~/api/auth'
import ApiBraintree from '~/api/braintree'
import ApiCheckout from '~/api/checkout'
import ApiCustomers from '~/api/customers'
import ApiCountries from '~/api/countries'
import ApiUsers from '~/api/users'
import ApiPartners from '~/api/partners'
import ApiSubscriptions from '~/api/subscriptions'
import ApiUrlPrefix from '~/api/url-prefix'
import ApiPrices from '~/api/prices'
import ApiVat from '~/api/vat'
import ApiVersion from '~/api/version'
import ApiInvoices from '~/api/invoices'
import ApiCreditNotes from '~/api/credit-notes'
import ApiAdmin from '~/api/admin'
import ApiResellers from '~/api/resellers'

const refreshCache: { inProgress: Promise<any> | undefined } = {
  inProgress: undefined,
}

export function useHttpClient() {
  const user = useUserStore()

  function getNewTokens() {
    const userId = user.getUserId()
    return ky
      .post('auth/token/refresh', {
        json: {
          refreshToken: user.getTokens.refresh,
          userId,
        },
        prefixUrl: import.meta.env.VITE_API_URL,
      })
      .json<AuthResponse>()
  }

  const kyInstance = ky.create({
    prefixUrl: import.meta.env.VITE_API_URL,
    timeout: 30000,
    retry: {
      limit: 2,
      methods: [
        'delete',
        'get',
        'head',
        'options',
        'patch',
        'post',
        'put',
        'trace',
      ],
      statusCodes: [401, 408, 413, 429, 501, 502, 503, 504],
    },
    hooks: {
      beforeRetry: [
        async ({ request, options, error, retryCount }) => {
          /**
           * If refresh token request is pending
           * wait for it for resolve
           */
          if (refreshCache.inProgress !== undefined) {
            await refreshCache.inProgress
          }
        },
      ],
      // Handle unauthorized request
      afterResponse: [
        async (request, _, response) => {
          if (response.status === 401) {
            /**
             * If there is no refresh token request pending
             * make a new one
             */
            const accessToken = user.getTokens.access
            if (refreshCache.inProgress === undefined && accessToken?.length) {
              refreshCache.inProgress = getNewTokens()
            }

            /**
             * When refresh token request is resolved
             * modify request headers and update user store
             *
             * Pay attention:
             * Ky afterResponse hook shouldn't return a request,
             * instead retry logic makes a request
             * inside the correct execution context
             * after new access token is set.
             */
            if (refreshCache?.inProgress) {
              refreshCache.inProgress
                .then((tokens) => {
                  user.setTokens(tokens.accessToken, tokens.refreshToken)
                  request.headers.set(
                    'Authorization',
                    `Bearer ${tokens.accessToken}`,
                  )
                  refreshCache.inProgress = undefined
                })
                .catch(() => {
                  if (!user.doNotLogout) {
                    // TO-DO: add proper fallback
                    logout('index')
                  }
                  refreshCache.inProgress = undefined
                })
            }
          }
        },
      ],
      // Add authorization header to requests
      beforeRequest: [
        (request) => {
          const accessToken = user.getTokens.access
          if (
            accessToken?.length &&
            !request.url.includes('/auth/token/refresh')
          ) {
            request.headers.set('Authorization', `Bearer ${accessToken}`)
          }
        },
      ],
    },
  })

  const api: Api = Object.assign(
    { auth: new ApiAuth(kyInstance, 'auth') },
    { braintree: new ApiBraintree(kyInstance, 'braintree') },
    { checkout: new ApiCheckout(kyInstance, 'checkout') },
    { customers: new ApiCustomers(kyInstance, 'customers') },
    { countries: new ApiCountries(kyInstance, 'countries') },
    { users: new ApiUsers(kyInstance, 'users') },
    { partners: new ApiPartners(kyInstance, 'partners') },
    { subscriptions: new ApiSubscriptions(kyInstance, 'subscriptions') },
    { urlPrefix: new ApiUrlPrefix(kyInstance, 'url-prefix') },
    { prices: new ApiPrices(kyInstance, 'prices') },
    { vat: new ApiVat(kyInstance, 'vat') },
    { version: new ApiVersion(kyInstance, 'version') },
    { invoices: new ApiInvoices(kyInstance, 'invoices') },
    { creditNotes: new ApiCreditNotes(kyInstance, 'credit-notes') },
    { admin: new ApiAdmin(kyInstance, 'admin') },
    { resellers: new ApiResellers(kyInstance, 'resellers') },
  )

  return { ...api }
}
