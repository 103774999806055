import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
import { router } from '~/router'
import { BillingPeriod } from '~/types/subscriptions'
import { Partner } from '~/types/partners'
import { Step } from '~/types/ui'
import { logout } from '~/components/checkout/utils'
import { CurrencyCode } from '~/types/countries'
import { CheckoutData, CheckoutPrices } from '~/types/checkout'

export const useCheckoutStore = defineStore('checkout', () => {
  const api = useHttpClient()
  const { t } = useTranslation()

  /* Steps */
  const steps: Step[] = [
    {
      name: t('login', { ns: 'checkout' }),
      title: 'login',
      routeName: 'checkout-sign-in',
    },
    {
      name: t('details', { ns: 'checkout' }),
      title: 'details',
      routeName: 'checkout-id-details',
    },
    {
      name: t('setup', { ns: 'checkout' }),
      title: 'setup',
      routeName: 'checkout-id-setup',
    },
    {
      name: t('paymentOptional', { ns: 'checkout' }),
      title: 'paymentSettings',
      routeName: 'checkout-id-payment',
    },
  ]
  const stepper = useStepper(steps)
  const activeStepIndex = ref(0)
  const userAgreesWithTerms = ref(false)

  function goToStep(to: number, redirect = true) {
    const { index, routeName } = stepper.goToStepNumber(to)
    if (!redirect) return
    const routeLocation =
      index === 0
        ? { name: routeName }
        : { name: routeName, params: { id: id.value } }

    router.push(routeLocation)
    return (activeStepIndex.value = index)
  }

  /**
   * Both checkout id and partnerId are stored in localStorage
   * and must be deleted when user is logged-out
   */
  const id = ref(useStorage('checkout-id', ''))
  const partnerId = ref(useStorage('partner-id', ''))
  function resetStore() {
    activeStepIndex.value = 0
    id.value = ''
    partnerId.value = ''
  }

  /**
   * The data is not stored in local storage, instead it is fetched from the DB
   * using checkoutId. The data is fetched inside the components whenever
   * we have a store with checkoutId but without data.
   */
  const data = reactive<CheckoutData>({
    addons: [],
    vatNumber: '',
    dunsNumber: '',
    state: '',
    phoneNumber: '',
    country: undefined,
    companyName: '',
    street: '',
    city: '',
    postalCode: '',
  })

  /* Billing address us optional in payment step */
  const billingDataSameAsCompany = ref(true)
  const billingData: Partial<CheckoutData> = reactive({
    street: '',
    city: '',
    postalCode: '',
    phoneNumber: '',
    country: undefined,
    vatNumber: '',
    state: '',
  })

  function getBillingData() {
    const payload = {
      billingCountry: data.country,
      billingVatNumber: data.vatNumber,
      billingCompanyName: data.companyName,
      billingState: data.state,
      billingStreet: data.street,
      billingCity: data.city,
      billingPostalCode: data.postalCode,
    }
    if (!billingDataSameAsCompany.value) {
      payload.billingCountry = billingData.country
      payload.billingState = billingData.state
      payload.billingStreet = billingData.street
      payload.billingCity = billingData.city
      payload.billingPostalCode = billingData.postalCode
    }
    return payload
  }

  /* Inits a checkout based on ID */
  async function init(checkoutId: string) {
    if (!checkoutId || typeof checkoutId !== 'string')
      logout('checkout-sign-in')

    // Set step on page load
    const routeOnLoad = useRoute()
    if (routeOnLoad) {
      const { index } = stepper.setStepBasedOnRoute(routeOnLoad)
      activeStepIndex.value = index
    }

    // Escape if checkout data store already has values
    const dataIsEmpty = () => {
      return !Object.values(data).some((dataItem) => {
        if (Array.isArray(dataItem)) return dataItem.length
        return !!dataItem
      })
    }
    if (!dataIsEmpty) return

    try {
      await updateCheckoutData(checkoutId)
      setPartners()
    } catch (error) {
      console.log(error)
    }
  }

  async function updateCheckoutData(checkoutId: string) {
    id.value = checkoutId
    try {
      const checkout = await api.checkout.get(checkoutId)
      data.addons = checkout.addons
      data.vatNumber = checkout.vatNumber
      data.dunsNumber = checkout.dunsNumber
      data.state = checkout.state
      data.phoneNumber = checkout.phoneNumber
      data.country = checkout.country
      data.companyName = checkout.companyName
      data.street = checkout.street
      data.city = checkout.city
      data.postalCode = checkout.postalCode
    } catch (error) {
      logout('checkout-sign-in')
    }
  }

  /* Countries */
  const countryInfo = computed(() => {
    const info = {
      region: '',
      phoneCode: '',
      code: data.country,
      currencyCode: CurrencyCode.EUR,
      currency: '€',
    }
    const { getCountryInfo } = useWorkspace()
    const selected = getCountryInfo(data.country ?? '')
    return selected ?? info
  })

  /* Printers */
  const selectedPrinters = ref(1)
  function setPrinters() {
    const addons = data.addons
    if (!addons?.length) return
    const numberOfPrinters = addons.find((item) => {
      if (item.type === 'CLOUDDESIGNER') return item
    })?.numberOfPrinters
    selectedPrinters.value = numberOfPrinters ?? 1
  }

  /* Pricing */
  const amountToPay = ref(0)
  const paymentPeriod = ref(BillingPeriod.MONTHLY)
  const prices: CheckoutPrices = reactive({
    MONTHLY: {
      currencyCode: undefined,
      discountPercentage: 0,
      subtotal: undefined,
      total: undefined,
    },
    YEARLY: {
      currencyCode: undefined,
      discountPercentage: 0,
      subtotal: undefined,
      total: undefined,
    },
  })
  async function updatePrices() {
    try {
      // const searchParams = {
      //   billingAddress: data.street,
      //   billingCity: data.city,
      //   billingPostalCode: data.postalCode,
      //   billingCountry: data.country,
      //   numOfPrinters: selectedPrinters.value,
      //   currencyCode: countryInfo.value.currencyCode,
      // }
      // TODO fix this
      // @ts-ignore
      // const [monthly, yearly] =
      // await api.prices.postSubscriptionEstimate(searchParams)
      const response = { monthly, yearly }
      let type: 'MONTHLY' | 'YEARLY'
      for (type in prices) {
        const key = type === 'MONTHLY' ? 'monthly' : 'yearly'
        prices[type].currencyCode = response[key].currencyCode
        prices[type].discountPercentage = response[key].discountPercentage
        prices[type].subtotal = response[key].subtotal / 100
        prices[type].total = response[key].total / 100
      }
    } catch (error) {
      console.log('Failed to update prices storage', error)
    }
  }

  /* Partners */
  const partnersShortlist = ref<Partial<Partner>[]>([])
  const selectedPartner = computed(() => {
    if (!partnerId.value) return undefined
    if (!partnersShortlist?.value?.length) return undefined
    const target = partnersShortlist.value.find(
      (partner) => partner.id === partnerId.value,
    )
    // If there is an ID, but it doesn't fit the new list
    if (!target) {
      const partner = partnersShortlist?.value[0]
      partnerId.value = partner.id ?? ''
      return partner
    }
    return target
  })
  async function setPartners() {
    try {
      partnersShortlist.value = await api.partners.getShortlist(
        countryInfo.value.code ?? '',
      )
      partnerId.value = partnerId.value ?? partnersShortlist.value[0].id
    } catch (error) {
      console.log(error)
    }
  }

  return {
    steps,
    activeStepIndex,
    goToStep,
    id,
    data,
    billingData,
    billingDataSameAsCompany,
    getBillingData,
    userAgreesWithTerms,
    partnerId,
    init,
    updateCheckoutData,
    resetStore,
    selectedPrinters,
    setPrinters,
    amountToPay,
    countryInfo,
    paymentPeriod,
    prices,
    updatePrices,
    partnersShortlist,
    selectedPartner,
    setPartners,
  }
})
